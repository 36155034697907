@keyframes shine {
  0% {
    background-position: 200%;
  }
  100% {
    background-position: -200%;
  }
}

.shining-text {
  color: transparent;
  background: linear-gradient(45deg, rgb(51, 51, 51), #ffffff, #ffffff);
  background-size: 200% 200%;
  background-position: -200% 200;
  -webkit-background-clip: text;
  background-clip: text;
  animation: shine 8s infinite linear;
}
