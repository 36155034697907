.product-card {
  // overflow: hidden;
  // margin-top: 20px;

  .background-video {
    pointer-events: none;
    transition: opacity 0.3s ease;
  }

  .hover-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: transform 1s ease, opacity 1s ease;
  }

  .hover-button {
    background-color: #b83d3d;
    opacity: 0;
    display: none;
    border-radius: 3px;
    transition: transform 1s ease, opacity 1s ease;
    text-transform: uppercase;
    font-weight: 600;
  }

  &:hover {
    .background-video {
      opacity: 0;
    }

    .hover-img {
      opacity: 1;
      top: 0;
    }

    .hover-button {
      transform: translateY(-10%);
      display: flex;
      opacity: 1;
    }
  }

  .card-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    padding-block: 10px;
    width: 100%;
    background-color: white;
    color: black;
    padding-inline: 16px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    justify-content: center;

    .card-heading {
      display: flex;
      width: 100%;
      gap: 5px;
      align-items: center;

      span:first-child {
        font-weight: 600;
      }
    }

    .card-descrption {
      // font-size: 10px;
      padding-top: 5px;
    }
  }
}
