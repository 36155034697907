@import "../../styles/variable.scss";

.action-card-view {
  border-radius: 10px;
  box-shadow: 10px 10px 30px $primary-color;
  transition: background-color 0.1s ease-in-out, padding-inline 0.1s ease-in-out;

  strong {
    font-weight: 800;
  }

  .action-card-heading {
    display: flex;
    justify-content: space-between;

    h2 {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
}

.active-card-style {
  background-color: #0066cc;
  transform: scale(1.05);
}
