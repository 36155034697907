@import "../../styles/variable.scss";

.footer-container {
  height: 65vh;

  .form-footer {
    background-color: #022359;
    height: 310px;
    width: 100%;

    img:nth-child(3) {
      transform: scale(1.8) translateY(10px);
    }
  }

  .footer-nav {
    .item {
      text-decoration: none;
      color: black;
      font-weight: 500;
      font-size: 0.9rem;
    }
  }

  .footer-description {
    color: rgba(0, 0, 0, 0.6);
  }

  .footer-divider {
    height: 1px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    margin-top: 35px;
  }

  .footer-end {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block: 40px;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.7);
  }
}

@media screen and (max-width: 850px) {
  .footer-container {
    height: auto;
  }

  .footer-end {
    flex-direction: column;
    align-items: start;
    justify-items: center;
  }
}

input {
  background-color: transparent;
  border: 1px solid white;
  border-radius: 5px;
  padding-left: 20px;
  color: white;

  &::placeholder {
    color: white;
  }

  &:focus {
    outline: none;
  }
}
