@import "./variable.scss";

.about-card-view {
  background-color: $primary-color-dim;
  color: white;
  z-index: 2;

  &::-webkit-scrollbar {
    display: none;
  }

  .stadium-view {
    z-index: 100;

    .stadium-background {
      z-index: 1;
      opacity: 1;

      .stadium-background-color {
        position: absolute;
        background: linear-gradient(
          178.99deg,
          #02173a 0.76%,
          rgb(3, 29, 75, 0.9) 90.26%
        );
        height: 100vh;
        width: 100vw;
        top: 0;
      }
    }

    .stadium-slider-view {
      z-index: 3;

      .stadium-slider-container {
        padding-bottom: 80px;
      }

      .watch-shop-view {
        gap: 50px;

        .mobile-video {
          height: 80vh;
        }

        .img-left {
          width: 45%;

          img {
            position: absolute;
            top: 15px;
            right: 40px;
            height: 45px;
            width: 45px;
          }

          video {
            border-radius: 10px;
          }
        }

        .img-center {
          width: 30%;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 35px;
          font-size: 4rem;
        }

        .img-right {
          width: 28%;
        }
      }
    }
  }
}
