@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #02173a;
}

html {
  scroll-behavior: smooth;
}

.slider-hidden-scrollbar::-webkit-scrollbar {
  display: none;
}
