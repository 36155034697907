@import "./variable.scss";

.announcement-view {
  height: auto;
  > * {
    flex: 1;
  }

  .announcement-heading {
    span:nth-child(1) {
      font-weight: 800;
    }

    .announcement-card-container {
      padding-top: 40px;
    }
  }
}

// .background-card {
//   background-color: $card-color;
// }

@media screen and (max-width: 850px) {
  .announcement-view {
    height: auto;
  }
}
